html, body {
    scroll-behavior: auto !important;
  }
body {
	font-size: 20px;
	font-family: 'Avantt';
}
body::-webkit-scrollbar{
    display: none !important;
}

@font-face {
	font-family: 'Avantt_semibold';
	src: url('../fonts/Avantt-SemiBold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Avantt_bold';
	src: url('../fonts/Avantt-Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Avantt_light';
	src: url('../fonts/Avantt-Light.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Avantt';
	src: url('../fonts/Avantt-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
.no-padding {
    padding: 0 !important;
}
#myVideo {
    position: fixed;
    left: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
}
.age-gate-content {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    background-color: rgb(0,0,0);
    background: rgba(0,0,0,0.5);
    overflow-x: hidden !important;
    transition: 0.5s;
    display: flex;
    align-items: center;
    border-bottom: 4px solid #e7c028;
    justify-content: center;    
}
.age-gate-content .logo {
    text-align: center;
}
.age-gate-content .logo p {
    font-size: 21px;
    font-weight: 400;
    margin-top: 30px;
    line-height: 28px;
    color: #fff;
}
.age-gate-footer {
    position: fixed;
    bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    left: 0;
    right: 0;
    text-align: center;
    color: #fff;
    z-index: 1000;
}
.age-gate-footer a {
    color: #fff;
}
.age-gate-footer p {
    margin: 5px 0;
}
.age-verification-form {
    margin-top: 30px;
}
.age-verification-form .form-control {
    font-size: 19px;
    padding: 16px 20px;
    font-weight: 600;
    background: rgba(225,225,225,0.3);
    border: 1px solid #fff !important;
    border-radius: 5px;
    color: #fff;
}
.age-verification-form .col-md-3 {
    padding: 0 5px;
}
.age-verification-form .form-control::placeholder {
    color: #fff;
}
.age-verification-form .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #fff;
}  
.age-verification-form .form-control::-ms-input-placeholder { /* Microsoft Edge */
   color: #fff;
}
.age-verification-form .btn {
    font-size: 19px;
    width: 100%;
    border: 0;
    text-transform: uppercase;
    text-align: center;
    padding: 16px 0;
    background-image: linear-gradient(to right top, #d3a238, #deb031, #e7c028, #efd01b, #f5e003);
    font-weight: 600;
}
.age-verification-form .btn a {
    text-decoration: none;
    color: #fff;
}
.common-section {
    padding: 80px 0 0 0;
}
.navbar-expand-lg .navbar-collapse.menu-links {
    text-align: center;
    /* display: block !important; */
    justify-content: center;
}
.menu-links .navbar-nav {
    display: inline-block !important;
    width: auto;
}
.menu-links .navbar-nav a {
    color: #fff;
    padding: 0 0px;
    letter-spacing: 1px;
    font-family: 'Avantt_semibold', sans-serif;
    text-decoration: none;
    font-size: 13px;
    display: inline-block;
    line-height: 30px;
    text-transform: uppercase;

}
/* .menu-links .navbar-nav a:hover {
    border-bottom: 2px solid #e6b611;
} */


.menu-links .navbar-nav a:hover,
.menu-links .navbar-nav a:focus {
    color: #f4e00f !important;
}

.active{
    color: #f4e00f !important;
}

.myclass-acctive {
    color: #f4e00f !important;
}


.menu-links .navbar-nav .hover-menu {
    display: inline-block;
    padding: 0 30px;
    line-height: 80px;
}
.menu-links .navbar-nav .hover-menu .sub-menu {
    display: none;
    position: relative;
}
.menu-links .navbar-nav .hover-menu .main-link-hover {
    color: #fff;
    font-size: 13px;
    letter-spacing: 1px;
    cursor: pointer;
    font-family: 'Avantt_semibold', sans-serif;
    text-transform: uppercase;
}
.header-menu {
    padding: 0;
    background: #000;
    z-index: 100000;
}
.menu-links .navbar-nav .hover-menu:hover .sub-menu {
    position: absolute;
    top: 60px;
    width: 225px;
    background: #000;
    display: block;
    padding: 20px 10px 10px 10px;
    line-height: initial;
}
.menu-links .navbar-nav .hover-menu.our-leaders-menu:hover .sub-menu {
    width: 330px;
}
.menu-links .navbar-nav .hover-menu:hover .sub-menu a {
    width: 100%;
    display: inline-block;
    text-align: left;
    line-height: 30px;
    padding: 5px 15px;
    text-transform: inherit;
}
footer {
    background: #000;
    margin-top: 54px;
    position: relative;
    z-index: 10000;
}
.footer-icons ul {
    list-style: none;
    text-align: center;
    margin: 0 0 30px 0;
    padding: 0;
}
.footer-icons ul li:first-child{max-width: 180px;}
.footer-icons ul li:first-child img{margin: 0;}
.footer-icons ul li {
    display: inline-block;
    margin-bottom: 30px;
}
.footer-icons ul li img {
    height: 65px;
    margin: 0 15px;
    filter: grayscale(100%);
    object-fit: contain;
}
.footer-btm ul.social-icons {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
}
.footer-btm ul.social-icons li {
    display: inline-block;
}
.footer-btm ul.social-icons li a {
    display: flex;
    width: 35px;
    height: 35px;
    /* align-items: center; */
    justify-content: center;
    background: #b8b8b8;
    border-radius: 10px;
    margin-right: 10px;
    color: #000;
    font-size: 18px;
}
.footer-btm ul.social-icons li a img {
    width: 15px;
}
.footer-btm ul.social-icons li:nth-child(2) img {
    width: 25px;
}
.footer-btm {
    padding-bottom: 0.1px;
}
.footer-btm p.copy-right {
    color: #fff;
    font-size: 15px;
    text-align: center;
    margin-bottom: 0;
}
.footer-btm .terms-policy {
    list-style: none;
    padding: 0;
    margin: 0;
}
.footer-btm .terms-policy li a {
    color: #fff;
    font-size: 15px;
    float: right;
    margin-left: 39px;
    text-decoration: none;
}
.footer-btm .row{align-items: center;}
.drink-resp .drink-resp-inner {
    margin: 0 auto;
    width: 70%;
    background-image: linear-gradient(to right top, #d3a238, #deb031, #e7c028, #efd01b, #f5e003);
    text-align: center;
    padding: 5px 0 15px 0;
    font-family: 'Avantt', sans-serif;
    position: relative;
    top: -55px;
}
.drink-resp .drink-resp-inner h4 {
    font-family: 'Avantt_semibold', sans-serif;
    color: #000;
    margin-top: 25px;
    text-transform: uppercase;
    font-size: 30px;
}
.drink-resp .drink-resp-inner p {
    font-size: 20px;
}
.home-banner {
    min-height: 500px;
    background-size: cover;
    max-height: 600px;
    overflow: hidden !important;
    max-width: 100%;
    position: relative;
}
.home-banner #homeVideoBanner {
    position: relative;
    left: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
}
.home-banner .banner-content {
    position: absolute;
    top: 350px;
    left: 0px;
    right: 50px;
}
.home-banner .banner-content h1 {
    font-family: 'Avantt_semibold', sans-serif !important;
    color: #fff;
    font-size: 47px;
    line-height: 68px;
    letter-spacing: 1px;
}
.home-banner .banner-content span {
    padding: 0 10px;
    color: #000;
    background-image: linear-gradient(to right top, #d3a238, #deb031, #e7c028, #efd01b, #f5e003);
}
.we-are-brewers .we-are-brewers-content {
    padding-top: 20px;
    padding-left: 50px;
}
.strip {
    width: 60px;
    height: 10px;
    background: #e6b611;
    display: inline-block;
    margin: 40px 0 5px 0;
}
.we-are-brewers .we-are-brewers-content h4 {
    font-family: 'Avantt_semibold', sans-serif;
    font-size: 38px;
    margin: 0 0 10px 0;
}
.we-are-brewers .we-are-brewers-content p {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -.1px;
    line-height: 30px;
    padding-right: 50px;
}
.we-are-brewers .image img {
    width: 100%;
}
.history-abinbev {
    text-align: center;
    padding: 70px 0;
}
.history-abinbev p {
    font-size: 29px;
    line-height: 43px;
}
.history-abinbev a {
    display: inline-block;
}
.common-link {
    color: #000;
    border: 1px solid #333;
    font-size: 17px;
    text-transform: uppercase;
    display: inline-block;
    padding: 12px 30px;
    margin-top: 10px;
    border-radius: 3px;
    letter-spacing: 1px;
    text-decoration: none;
}
.common-link:hover {
    background-image: linear-gradient(to right top, #d3a238, #deb031, #e7c028, #efd01b, #f5e003) !important;
    border: 1px solid #d3a238;
    color: #000;
}
.what-we-do ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-block;
    width: 100%;
}
.what-we-do ul li {
    float: left;
    width: calc(50% - 15px);
    position: relative;
}
.what-we-do ul li .port {
    width: 100%;
    position: relative;
    overflow: hidden !important;
}
.what-we-do ul li .image img {
    width: 100%;
}
.what-we-do ul li .port .portfolioDisc {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    z-index: 1000;
    margin: 0;
    height: 280px;
}
.what-we-do ul li .port .portfolioDisc .details {
    height: 100%;
    position: relative;
}
.what-we-do ul li .port .portfolioDisc .details .details-main {
    position: absolute;
    bottom: 0;
    padding: 20px;
    max-width: 332px;
    width: 100%;
}
.what-we-do ul li .portfolioDisc .details p {
    color: #fff;
    font-size: 15px;
    letter-spacing: -.1px;
    line-height: 30px;
    margin-bottom: 17px;
    min-height: 120px;
}
.what-we-do ul li .details h2{font-size: 1.6rem;}
.what-we-do ul li .details a {
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    padding: 7px 12px;
    letter-spacing: 1px;
    display: inline-block;
    text-decoration: none;
    border: 1px solid #fff;
    border-radius: 3px;
    margin-top: 10px;
}
.what-we-do ul li:last-child {
    float: right;
}
.what-we-do ul li .portfolioDisc {
    background-color: rgba(0,0,0,0.5);
    font-size: 15px;
    line-height: 18px;
    color: #fff;
    padding: 20px;
}
.what-we-do ul li .details a:hover {
    background-image: linear-gradient(to right top, #d3a238, #deb031, #e7c028, #efd01b, #f5e003) !important;
    border: 1px solid #d3a238;
    color: #000;
}
.what-we-do {
    padding: 40px 0 54px;
}
.cyber_team .details {
    display: flex;
    align-items: center;
}
.common-heading {
    font-size: 34px;
    font-family: "Avantt_semibold", sans-serif;
    text-transform: uppercase;
    font-weight: 900;
}
.common-heading span {
    color: #e6b611;
}
.what-we-do-heading p {
    font-size: 20px;
    margin-bottom: 30px;
}
.common-footer-top {
    background-image: url(../images/view_all_bg.png);
    padding-top: 60px;
    margin: 0 !important;
    padding-bottom: 60px;
    background-size: cover;
    background-position: center center;
}

.common-footer-top h3 {
    margin-bottom: 30px;
    font-size: 22px;
}
.common-footer-top .animate-scroll-new {
    font-size: 45px;
    margin: 0;
    text-transform: uppercase;
    font-family: 'Flama Condensed', Arial, Helvetica, sans-serif;
    font-weight: bold;
    line-height: 50px;
    letter-spacing: -1px;
    font-size: 36px;
    font-family: 'Avantt_semibold', sans-serif;
}
.animate-scroll-new span {
    color: #e6b611 !important;
}
.common-footer-top a {
    display: inline-block;
    color: #000;
    border: 1px solid #000;
    padding: 10px 30px;
    border-radius: 3px;
    margin-top: 30px;
    font-size: 17px;
    text-decoration: none;
}
.common-footer-top a:hover {
    background-image: linear-gradient(to right top, #d3a238, #deb031, #e7c028, #efd01b, #f5e003) !important;
    border: 1px solid #d3a238 !important;
    color: #000 !important;
}
.carousel-brands .images {
    float: left;
    width: 50%;
    text-align: center;
}
.carousel-brands .details {
    float: left;
    width: 50%;
    color: #000;
}
.carousel-brands .details .carousel-caption {
    position: static;
    padding-top: 95px;
    color: #000;
    text-align: left;
}
.carousel-brands .carousel-caption h3 {
    text-transform: uppercase;
    font-weight: 600;
}
.carousel-brands .details .carousel-caption h3 img {
    width: 50px;
    margin-right: 20px;
}
.carousel-brands .details .carousel-caption h3 span {
    text-transform: uppercase;
    font-size: 35px;
    position: relative;
    top: 4px;
}
.our-brands-home .carousel-brands .carousel-caption h3 {
    font-size: 35px;
}
.carousel-brands .details .carousel-caption p {
    font-size: 20px;
    margin-bottom: 40px;
    line-height: 33px;
}
.carousel-brands .details .carousel-caption  a {
    color: #333;
    font-size: 20px;
    display: inline-block;
    margin-top: 20px;
    text-decoration: none;
}
.carousel-brands .carousel-indicators [data-bs-target] {
    width: 35px;
    height: 5px;
    background-color: #ccc;
}
.carousel-brands .images img {
    height: 500px;
    width: auto !important;
    display: inline-block !important;
}
.react-rotating-text-cursor {
    animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
}
   
  @keyframes blinking-cursor {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.campus-programmes {
    background: #f8f8f8;
    padding: 50px 0;
}
.campus-programmes .campus-prog {
    margin-top: 30px;
}
.campus-programmes .campus-prog .campus-list {
    padding: 0 10px;
}
.campus-programmes .campus-prog .campus-list .image img {
    width: 100%;
}
.campus-programmes .campus-prog .campus-list .details h4 {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 17px;
    font-family: 'Avantt_semibold', sans-serif;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
    margin: 20px 0;
    letter-spacing: 0.6px;
}
.campus-programmes .campus-prog .campus-list .details p {
    font-size: 14px;
    line-height: 23px;
    color: #666;
    min-height: 170px;
}
.campus-programmes .campus-prog .campus-list .details a {
    color: #000;
    font-size: 17px;
    border: 1px solid #999;
    display: inline-block;
    padding: 10px 30px;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 1px;
    border-radius: 3px;
    text-decoration: none;
}
.campus-programmes .campus-prog .campus-list .details a:hover {
    text-decoration: none;
    background-image: linear-gradient(to right top, #d3a238, #deb031, #e7c028, #efd01b, #f5e003) !important;
    border: 1px solid #d3a238 !important;
    color: #000 !important;
}
.campus-programmes .campus-prog .slick-prev:before, .campus-programmes .campus-prog .slick-next:before {
    color: #000;
}
.common-banner {
    background-image: url(../images/our_values/our_values.png);
    background-size: cover;
    background-position: center;
    height: calc(100vh - 257px);
    position: relative;
    margin-top: 80px;
    background-color: rgba(0,0,0,0.5) !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.common-banner .common-banner-inner {
    color: #fff;

}
.common-banner .common-banner-inner h1 {
    font-family: 'Avantt_semibold', sans-serif !important;
    font-size: 60px;
    width: 100%;
    color: #e6b611;
    text-align: center;
}
.DEI_STATEMENT .di-list-table {
    margin-top: 0;
}
section.common-banner.diversity-banner .common-banner-inner h1{font-size: 50px;}

.common-banner .common-banner-inner h1 span {
    color: #fff;
}
.common-banner .common-banner-inner p {
    font-size: 20px;
    position: relative;
    color: #fff;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
}
.banner_class img {
    width: 100%;
}
.our-values p {
    text-align: center;
    padding: 70px 0 30px;
    font-size: 24px;
    line-height: 40px;
}
.our-values-img img {
    width: 100%;
}
.our-values-img {
    padding: 0px 0 50px;
}
.our-values-img h4 {
    font-size: 35px;
    font-family: 'Avantt_semibold', sans-serif;
    margin-top: 30px;
    color: #e5b611;
}
.our-values-list {
    padding-bottom: 50px;
}
.our-values-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.our-values-list ul li {
    margin-bottom: 0;
}
.our-values-list ul li .list img {
    width: 80px;
    display: inline-block;
    vertical-align: top;
}
.our-values-list ul li .list .image {
    display: inline-block;
    vertical-align: top;
    width: 80px;
}
.our-values-list ul li:first-child {
    margin-bottom: 30px;
}
.our-values-list ul li .list span.dream, span.people, span.culture {
    background: #000 !important;
    color: #e6b611;
    text-align: center;
}
.our-values-list ul li .list span {
    display: inline-block;
    vertical-align: top;
    width: 125px;
    background: #fff;
    padding: 4px 15px;
    border-radius: 22px;
    margin: 0 10px;
    margin-top: 21px;
    font-size: 18px;
    letter-spacing: 2px;
}
.our-values-list ul li .list p {
    display: inline-block;
    width: calc(100% - 235px);
    vertical-align: top;
    margin-top: 24px;
}
.our-values-list ul li .list p.second-circle i, .our-values-list ul li .list p i {
    border: 1px solid #e5b611;
    color: #000;
    display: inline-block;
    width: 25px;
    text-align: center;
    font-style: inherit;
    height: 25px;
    line-height: 25px;
    border-radius: 50%;
    font-size: 18px;
    margin-right: 10px;
}
.our-values-list ul li .list p span {
    vertical-align: top;
    width: 80%;
    margin: 0;
    letter-spacing: inherit;
    padding: 0;
    display: inline-block;
    font-size: 18px;
}
.sustaibility-banner {
    background-image: url(../images/sustainability/OUR-SUSTAINABILITY-GOALS.png);   
}
.common-cp-head p.content {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -.1px;
    line-height: 30px;
    margin-bottom: 17px;
}
.common-cp-head {
    padding: 60px 0 0 0;
}
.common-cp-head ul {
    padding-left: 40px;
    color: #000;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -.1px;
    line-height: 30px;
    margin-bottom: 17px;
    list-style-type: none;
    display: inline-block;
    width: calc(100% + 10px);
    margin: 50px 0 0 0;
    padding: 0;
}
.sustainability-list li {
    float: left;
    padding: 5px;
    width: 25%;
    margin-bottom: 20px;
}
.sustainability-list li img {
    width: 100%;
}
.sustainability-content ul li h3 {
    font-family: 'Avantt_semibold', sans-serif;
}
.sustainability-list .container, .sustainability-content .container {
    width: 1200px;
}
.our-brands-page-banner-text h1, .rotate-text, .common-cp-head h3 {
    font-family: 'Avantt_semibold', sans-serif;
}
.rotate-text, .common-cp-head h3 {
    font-size: 1.7rem;
    text-align: center;
}
.common-cp-head h2{
    text-align: center;
    font-weight: bold;
}
.sustainability-content h3{font-weight: bold;}
.sustainability-content {
    padding: 20px 0 0;
}
.sustainability-content ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.sustainability-content ul li {
    margin-bottom: 40px;
}
.sustainability-content ul li p {
    color: #000;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -.1px;
    line-height: 30px;
}
.common-banner .common-banner-inner .yel-col{
    color: #e6b611 !important;
    font-size: 25px;
}
.diversity-banner {
    background-image: url(../images/diversity_inclusion/D&I.png);
}
.diversity-inclusion {
    padding: 100px 0 0 0;
}
.diversity-inclusion .top-content-di {
    font-size: 26px;
    text-align: center;
    letter-spacing: 1.2px;
    font-family: 'Flama Condensed', Arial, Helvetica, sans-serif;
    line-height: 34px;
}
.diversity-inclusion .top-content-di h2 {
    font-family: 'Avantt_semibold', sans-serif;
}
.diversity-inclusion .top-content-di p {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -.1px;
    line-height: 30px;
}
.di-list-table {
    display: table;
    width: 100%;
    margin-top: 100px;
}
.di-list-table .di-list-table-left {
    display: table-cell;
    width: 50%;
    vertical-align: middle;
}
.di-list-table .di-list-table-left .details h2 {
    font-size: 37px;
    font-family: 'Avantt_semibold', sans-serif;
    margin-top: 0;
    letter-spacing: 1.2px;
}
.di-list-table .di-list-table-left .details h2 span, .di-list-table .di-list-table-left .details h2 span {
    color: #e6b611 !important;
}
.di-list-table .di-list-table-left .details p {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -.1px;
    line-height: 30px;
}
.di-list-table .di-list-table-right {
    display: table-cell;
    width: 35%;
    padding-left: 50px;
}
.initiatives-di {
    padding: 0 0 20px 0;
}
.our-initiatives-heading {
    margin-top: 100px;
    font-size: 50px;
    font-family: "Avantt_semibold", sans-serif;
}
.our-initiatives-heading span {
    color: #e6b611;
}
.di-list-table.di-list-table-2 {
    display: table;
    width: 100%;
    margin-top: 0;
}
.di-list-table.di-list-table-2 .di-list-table-right {
    padding-left: 0;
    padding-right: 50px;
    width: 45%;
}
.di-list-table.di-list-table-2 .di-list-table-right img.top-img {
    width: 60%;
    float: right;
    position: relative;
    top: 60px;
}
.di-list-table.di-list-table-2 .di-list-table-right img.btm-img {
    position: relative;
    width: 60%;
    margin-top: -40px;
}
.di-list-table .di-list-table-left {
    display: table-cell;
    width: 50%;
    vertical-align: middle;
}
.di-list-table .di-list-table-left .details h2 {
    font-size: 37px;
    text-transform: uppercase;
    font-family: 'Avantt_semibold', sans-serif;
}
.more-about {
    font-family: 'Avantt_semibold', sans-serif;
    font-size: 35px !important;
    text-transform: uppercase;
}
.more-about span {
    color: #e6b611 !important;
}
.parental-policy-box .program-details.next-list {
    text-align: center;
    box-shadow: 0 0 7px #e9e4e4;
    height: 100%;
    padding: 15px;
}
.program-details .image {
    margin-top: 20px;
}
.program-details.next-list .image img {
    height: 100px;
    width: auto;
}
.parental-policy-box .program-details.next-list .details h2 {
    font-size: 18px;
    margin-top: 20px;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    font-family: 'Avantt_semibold', sans-serif;
}
.parental-policy-box .program-details.next-list .details p {
    font-size: 16px;
    line-height: 26px;
    color: #666;
    min-height: initial;
    margin: 15px 0 30px 0;
}
.diversity-inclusion-equal {
    padding: 20px 0;
}
.diversity-inclusion-equal h2 {
    font-size: 37px;
    text-align: center;
    font-family: 'Avantt_semibold', sans-serif;
}
.diversity-inclusion-equal h2 span {
    color: #e6b611 !important;
}
.diversity-inclusion-equal p {
    margin: 20px 0 30px 0;
    font-size: 20px;
    text-align: center;
    font-weight: 400;
    letter-spacing: -.1px;
    line-height: 30px;
    color: #000;
}
ul.diversity-awards-top {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-block;
    width: 100%;
}
ul.diversity-awards-top li {
    text-align: center;
    float: left;
    width: 25%;
}
ul.diversity-awards-top li img {
    width: auto;
    height: 100px;
}
ul.diversity-awards-top li p {
    font-size: 17px;
    line-height: 24px;
    padding: 0 13px;
    margin: 20px 0 30px 0;
}
ul.diversity-awards-top li:nth-child(2) img {
    height: 50px;
    margin: 24px 0;
}
.all-diverse-slates {
    background: #000;
    padding: 100px 0;
}
.all-diverse-slates h3 {
    color: #fff;
    font-family: 'Avantt_semibold', sans-serif;
    font-size: 35px !important;
    text-transform: uppercase;
}
.main-img-slider a {
    position: relative;
    width: 25%;
    float: left;
    padding: 5px;
}
.main-img-slider a img {
    width: 100%;
}
.diversity-awards {
    list-style: none;
    padding: 0;
    margin: 0;
}
.diversity-awards li {
    width: 25%;
    float: left;
    padding: 5px;
}
.diversity-awards li a {
    width: 100%;
    background: #fff;
    padding: 0;
}
.diversity-awards li a.title {
    border-radius: 0 0 5px 5px;
    text-decoration: none;
    text-align: center;
    padding: 15px 0;
    color: #000;
}
.gcc-banner {
    background: url(../images/GCC_India/GCC-INDIA.png);
}

.common-heading-sub {
    padding: 50px 0;
}
.common-heading-sub h3 {
    font-size: 37px;
    font-family: 'Avantt_semibold', sans-serif;
    color: #000;
    text-align: center;
    letter-spacing: 1px;
}
.common-heading-sub h3 span {
    color: #e6b611;
}
.common-heading-sub p {
    font-size: 24px;
    text-align: center;
    line-height: 34px;
    margin-top: 20px;
}
.carousel-main-teams {
    display: inline-block;
    width: 100%;
}
h2.head-rotate {
    font-size: 47px;
    color: #e6b611;
    font-family: 'Avantt_semibold', sans-serif;
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    position: relative;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0;
}
.carousel-teams .image {
    float: left;
    width: 40%;
    text-align: right;
    padding: 0px;
}
.carousel-teams .details-main {
    float: left;
    width: 60%;
    padding-left: 60px;
}
.carousel-teams .details-main p {
    line-height: 26px;
    font-size: 18px;
    font-weight: 400;
    margin-top: 0;
    letter-spacing: -.1px;
}
.carousel-teams .image img {
    width: 100%;
}
.carousel-teams {
    padding: 50px 0;
}
.team-name-main {
    font-size: 40px;
    display: inline-block;
    width: 100%;
    text-transform: uppercase;
    margin-top: 30px;
    font-family: 'Avantt_semibold', sans-serif !important;
}
.list-teams {
    display: inline-block;
    width: 100%;
}
.details-team-dots {
    position: relative;
    cursor: pointer;
}
.details-team-dots .box-teams {
    border: 1px dashed #000;
    position: relative;
    min-height: 130px;
    margin-right: 10px;
}
.details-team-dots .team-name {
    position: absolute;
    top: 50px;
    left: 15px;
    color: #000;
    font-size: 17px;
    right: 25px;
    text-transform: uppercase;
    transition: top ease 0.5s;
    display: table;
    width: 100%;
    padding-right: 40px;
    min-height: 45px;
  }
  .details-team-dots .team-name span {
    display: table-cell;
    vertical-align: bottom;
    width: 100%;
    font-size: 16px;
}
.click-more {
    border-radius: 3px;
    position: absolute;
    left: 15px;
    bottom: 37px;
    border: 1px solid #fff;
    color: #fff;
    transition: all .2s ease-in-out;
    text-transform: uppercase;
    padding: 4px 15px;
    opacity: 0;
    font-size: 15px;
  }
  .details-team-dots:hover .click-more {
	opacity: 1;
	transition: all .2s ease-in-out;
}
.details-team-dots:hover .box-teams {
	background-color: #000 !important;
}
.details-team-dots:hover .team-name {
    color: #fff;
    top: 15px;
}
  .details-team-dots:hover .team-name span {
	vertical-align: top;
	color: #e6b611;
}
.details-team-dots:hover .box-teams:before {
    background: #fff;
}
.details-team-dots .box-teams:before {
    position: absolute;
    left: 15px;
    bottom: 20px;
    width: 60px;
    height: 5px;
    content: "";
    background: #e6b611;
}
.slick-prev:before, .slick-next:before {
    color: #000 !important;
}
.teams-modal-popup .heading {
    color: #e6b611;
    text-transform: uppercase;
    font-family: 'Avantt_semibold', sans-serif;
}
.teams-modal-popup .modal-body {
    font-size: 18px;
}
.analytics-main {
    background: #f8f8f8;
}
.analytics-main .carousel-teams .details-main {
    padding-left: 0;
    padding-right: 60px;
}
.benefits-list .image {
    margin-top: 20px;
}
.benefits-list .image img {
    width: 100%;
}
.benefits-list .details h2 {
    font-family: 'Avantt_semibold', sans-serif;
    font-size: 22px;
    margin-top: 20px;
    text-transform: uppercase;
}
.benefits-list .details p {
    font-size: 17px;
    line-height: 28px;
    min-height: 57px;
    color: #666;
    margin: 15px 0 30px 0;
}
.benefits-list .details a {
    color: #000;
    font-size: 17px;
    border: 1px solid #999;
    display: inline-block;
    padding: 10px 13px;
    text-transform: uppercase;
    font-weight: 900;
    border-radius: 3px;
    text-align: center !important;
    width: 100%;
    text-decoration: none;
}
.benefits-list .details a:hover {
    background-image: linear-gradient(to right top, #d3a238, #deb031, #e7c028, #efd01b, #f5e003) !important;
    border: 1px solid #d3a238 !important;
    color: #000 !important;
}
.awards-banner {
    padding: 80px 0;
}
.awards-banner .heading {
    color: #e6b611 !important;
    font-family: 'Avantt_semibold', sans-serif !important;
    text-transform: uppercase;
}
.awards-banner .carousel-banner {
    border: 1px dashed #333;
    margin-top: 20px;
    padding: 20px 20px 65px 20px;
}
.carousel-banner .common-car-details {
    display: table;
    width: 100%;
    vertical-align: middle;
}
.carousel-banner .common-car-details .common-car-left {
    display: table-cell;
    width: 50%;
    padding-right: 50px;
}
.carousel-banner .common-car-details .common-car-left img {
    height: 350px;
}
.carousel-banner .common-car-details .common-car-right {
    vertical-align: middle;
    width: 50%;
    padding-right: 30px;
    display: table-cell;
}
.carousel-banner .common-car-details .common-car-right h4 {
    color: #e6b611;
    font-size: 30px;
}
.carousel-banner .common-car-details .common-car-right p {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -.1px;
    line-height: 30px;
    margin-bottom: 17px;
}
.view-award {
    font-size: 17px;
    color: #000;
    letter-spacing: 1px;
    border: 1px solid #000;
    padding: 10px 30px;
    border-radius: 3px;
    display: inline-block;
    margin-top: 5px;
    text-decoration: none;
    text-transform: uppercase;
}
.view-award:hover {
    background-image: linear-gradient(to right top, #d3a238, #deb031, #e7c028, #efd01b, #f5e003) !important;
    border: 1px solid #d3a238 !important;
    color: #000 !important;
}
.carousel-banner .carousel-indicators {
    bottom: -45px;
}
.carousel-banner .carousel-indicators [data-bs-target] {
    background-color: #ccc;
}
.bu-banner {
    background: url(../images/BU_India/BU-INDIA.png);
}
.bu-common-bu .bu-common-main {
    display: table;
    width: 100%;
    margin-bottom: 50px;
}
.bu-common-bu .bu-common-main .bu-left {
    display: table-cell;
    width: 50%;
    padding: 0 20px;
    vertical-align: middle;
}
.bu-common-bu .bu-common-main .bu-right h4, .bu-common-bu .bu-common-main .bu-left h4 {
    color: #e6b611;
    font-size: 37px;
    font-family: 'Avantt_semibold',sans-serif;
    text-transform: uppercase;
}
.bu-common-bu .bu-common-main .bu-left img {
    width: 100%;
}
.bu-common-bu .bu-common-main .bu-right {
    display: table-cell;
    width: 50%;
    padding: 0 20px;
    vertical-align: middle;
}
.bu-common-bu .bu-common-main .bu-right p {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 400;
    letter-spacing: -.1px;
    line-height: 26px;
}
.our-entities {
    margin-bottom: 50px;
}
.common-table {
    display: table;
    width: 100%;
}
.common-table .common-table-left {
    display: table-cell;
    vertical-align: middle;
    width: 100px;
    left: -50px;
    position: relative;
}
.common-table-left h2 {
    display: block;
    position: absolute;
    margin: 0;
    padding: 0;
    left: 0;
    top: 100px;
    text-align: left;
    color: white;
    transform: rotate(-90deg);
    transition-property: left right transform;
    transition-duration: 250ms;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 37px;
    color: #e6b611;
    font-family: 'Avantt_semibold', sans-serif;
    letter-spacing: 3px;
    left: -115px;
}
.common-table .common-table-right {
    display: table-cell;
    width: 100%;
}
.leader-new-list .profile-details {
    cursor: pointer;
    box-shadow: 0 0 1px #333;
}
.leader-new-list .profile-details .details {
    padding: 10px 10px 3px;
}
.profile-details {
    margin-bottom: 50px;
}
.profile-details .image {
    overflow: hidden !important;
    background: #8d8d8d;
}
.profile-details .image:hover img {
    transform: scale(1.03);
}
.leader-new-list .profile-details .image img {
    height: 305px;
    transition: 0.5s all ease-in-out;
    width: 100%;
    object-fit: cover;
}
.profile-details .details h4, .leaders-modal-popup .details-modal-right h4 {
    margin-bottom: 0;
    color: #000;
    opacity: 1;
    font-size: 20px;
    margin-top: 15px;
    text-transform: uppercase;
    font-weight: bold;
}
.leader-new-list .profile-details .details p, .leaders-modal-popup .details-modal-right .sub-head {
    color: #000 !important;
    text-transform: inherit;
    font-size: 18px;
    line-height: 20px;
    margin-top: 10px;
    min-height: 50px;
}
.leaders-list .container {
    width: 1170px;
}
.leaders-modal-popup .modal-header {
    padding: 0;
    border: 0;
    position: relative;
}
.leaders-modal-popup .modal-header .btn-close {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1000;
}
.leaders-modal-popup .details-modal-left {
    float: left;
    width: 35%;
    margin-right: 25px;
    box-shadow: 0 0 1px #333;
    padding: 5px;
}
.leaders-modal-popup .details-modal-right {
    padding-right: 30px;
}
.leaders-modal-popup .details-modal-right .sub-head {
    color: #e6b611;
    font-size: 17px;
    margin: 0 0 12px 0;
    height: auto;
    min-height: unset;
}
.leaders-modal-popup .modal-body {
    padding: 25px;
}
.leaders-modal-popup .details-modal-left img {
    width: 100%;
    margin-bottom: 0px;
    padding-right: 0px;
}
.leaders-modal-popup .details-modal-right .content {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.1px;
    line-height: inherit;
}
.leaders-modal-popup .details-modal-right h4 {
    margin-bottom: 10px;
    font-size: 22px;
}
.bu-leaders-banner {
    background: url(../images/BU_Leaders/OUR-LEADERS_BU.png);
}

.leap-banner {
    background: url(../images/programmes/LEAP-PROGRAMME.png);
}
.brew-banner {
    background: url(../images/programmes/BREW-Programme.png);
}
.sales-banner {
    background: url(../images/programmes/SALES-VISIONARIES-PROGRAMME.png);
}
.supply-banner {
    background: url(../images/programmes/Supply-Management-Traineeship.png);
}
.main-brands-banner {
    background: url(../images/our_brands/Brands.png);
}
.common-programme-content {
    padding: 40px 0 0 0;
}
.common-programme-content p {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -.1px;
    line-height: 30px;
    margin-bottom: 17px;
}
.common-programme-content h3 {
    font-family: 'Avantt_semibold', sans-serif;
}
.common-programme-content ul li {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -.1px;
    line-height: 30px;
    margin-bottom: 17px;   
}
.common-programme-content ul li b {
    font-family: 'Avantt_semibold';   
}
ul.selection-process-new {
    list-style: none;
    padding: 0;
    margin: 50px 0;
    display: inline-block;
    width: 100%;
    position: relative;
    color: #000;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -.1px;
    line-height: 30px;
}
ul.selection-process-new li {
    width: 100%;
    display: table;
    margin: 0;
}
ul.selection-process-new li .right-content {
    display: table-cell;
    width: 50%;
    text-align: right;
    padding: 0 70px 0 160px;
    position: relative;
}
ul.selection-process-new li .left-content span, ul.selection-process-new li .right-content span {
    color: #e6b611;
}
ul.selection-process-new li .right-content span {
    font-size: 83px;
    top: 6px;
}
ul.selection-process-new li .right-content span {
    width: 50px;
    height: 50px;
    line-height: 45px;
    left: 29px;
    border-radius: 50%;
    text-align: center;
    font-size: 120px;
    background: #fff;
    display: inline-block;
    vertical-align: top;
    opacity: 0.3;
    position: relative;
    top: 14px;
}
ul.selection-process-new li .right-content h4 {
    font-size: 29px;
    margin: 0 0 13px 0;
    display: inline-block;
    font-family: 'Avantt_semibold', sans-serif;
    letter-spacing: inherit;
    text-transform: uppercase;
    font-weight: bold;
}
ul.selection-process-new li .right-content p {
    font-size: 16px;
    line-height: 24px;
}
ul.selection-process-new li .line-left {
    display: table-cell;
    width: 50%;
    min-height: 160px;
    position: relative;
}
.hexagon-syble {
    border-left: 4px solid #e6b611;
    border-right: 4px solid #e6b611;
}
.hexagon-syble {
    height: 50px;
    width: 75px;
    border-radius: 3px;
    background: #fff;
    position: absolute;
    left: -38px;
    top: 0;
    text-align: center;
}
.line-left:before, ul.selection-process-new li .line-right:before {
    border-left: 4px solid #e6b611;
}
.line-left:before {
    content: "";
    position: absolute;
    bottom: 17px;
    left: -3px;
    height: calc(100% - 87px);
}
.hexagon-syble .arrow-btm {
    position: relative;
}
.hexagon-syble .arrow-btm:after, .hexagon-syble .arrow-btm:before, .hexagon-syble .arrow-top:before, .hexagon-syble .arrow-top:after {
    background: #e6b611;
}
.hexagon-syble .arrow-btm:before {
    content: "";
    position: absolute;
    width: 40px;
    height: 4px;
    transform: rotate(-30deg);
    left: -4px;
    top: -11px;
}
.hexagon-syble .arrow-btm:after {
    content: "";
    position: absolute;
    width: 41px;
    height: 4px;
    transform: rotate( 30deg);
    right: -4px;
    top: -11px;
}
.hexagon-syble .arrow-top:before {
    content: "";
    position: absolute;
    width: 40px;
    height: 4px;
    transform: rotate( 30deg);
    left: -4px;
    bottom: -11px;
}
.hexagon-syble svg {
    color: #e6b611;
    width: .875em;
}
.svg-inline--fa.fa-w-18 {
    width: 1.125em;
}
.hexagon-syble svg {
    position: relative;
    font-size: 33px;
    top: -20px;
}
.hexagon-syble .arrow-top:after {
    content: "";
    position: absolute;
    width: 41px;
    height: 4px;
    transform: rotate( -30deg);
    right: -4px;
    bottom: -11px;
}
ul.selection-process-new li .line-right {
    width: 50%;
    display: table-cell;
    min-height: 160px;
    position: relative;
}
ul.selection-process-new li .line-right .hexagon-syble {
    left: inherit;
    right: -38px;
}
ul.selection-process-new li .left-content {
    display: table-cell;
    width: 50%;
    position: relative;
    padding: 0 100px 0 70px;
}
ul.selection-process-new li .left-content h4 {
    font-size: 29px;
    margin: 0 0 13px 0;
    display: inline-block;
    font-family: 'Avantt_semibold', sans-serif;
    letter-spacing: inherit;
    text-transform: uppercase;
    font-weight: bold;
}
ul.selection-process-new li .left-content span {
    font-size: 83px;
    top: 6px;
}
ul.selection-process-new li .left-content span {
    width: 50px;
    height: 50px;
    line-height: 45px;
    left: -25px;
    border-radius: 50%;
    text-align: center;
    background: #fff;
    display: inline-block;
    vertical-align: top;
    opacity: 0.3;
    position: relative;
}
ul.selection-process-new li .line-right:before {
    content: "";
    position: absolute;
    bottom: 17px;
    right: -3px;
    height: calc(100% - 87px);
}
.selection-process-new:after {
    border-top-color: #e6b611;
}
.selection-process-new:after {
    top: 97%;
}
.selection-process-new:after {
    top: 98%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-top-color: #e6b611;
    border-width: 15px;
    margin-left: -15px;
}

.kick-start-heading span a {
    display: inline-block;
    color: #000;
    border: 1px solid #000;
    padding: 10px 30px;
    border-radius: 3px;
    margin-top: 30px;
    font-size: 24px;
    text-decoration: none;
    text-transform: uppercase;
}
.kick-start-heading span a:hover {
    background-image: linear-gradient(to right top, #d3a238, #deb031, #e7c028, #efd01b, #f5e003) !important;
    border: 1px solid #d3a238 !important;
    color: #000 !important;
}
.svg-inline--fa.fa-w-20 {
    width: 1.25em;
}
ul.selection-process-new li .left-content p {
    font-size: 16px;
    line-height: 24px;
}
.our-brands-content {
    padding: 30px 0 80px 0;
    border-bottom: 40px solid #000;
}
.our-brands-content p {
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    padding-top: 30px;
}
.beers-brands {
    padding: 0px 0 50px;
    background: #000;
    margin-bottom: 110px;
}
.beers-list.indian-banner p {
    font-size: 16px;
}
.rotate-text {
    font-size: 37px;
    color: #e3bc21;
    top: 310px;
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    position: relative;
    left: 0px;
    letter-spacing: 1px;
    margin: 0;
    font-family: 'Avantt_semibold', sans-serif;
}
.beers-list.global-banner {
    background-image: url(../images/our_brands/map-graphic.jpeg);
    background-size: cover;
    padding: 15px 12px 7px 7px;
    background-repeat: no-repeat;
    background-position: center;
}
.beers-list.indian-banner {
    background-image: url(../images/our_brands/map-graphic.jpeg);
    background-size: cover;
    padding: 15px 12px 7px 7px;
    background-repeat: no-repeat;
    background-position: center;
}
.non-alchoholic-banner {
    background-image: url(../images/our_brands/non-alcholic-bg.jpeg);
    background-size: cover;
    padding: 15px 12px 7px 7px;
    background-repeat: no-repeat;
    background-position: center;
}
.list-card .flip-card {
    width: 100%;
    height: 500px;
    perspective: 1000px;
    padding: 0 0 0 5px;
}
.list-card .flip-card-inner {
    position: relative;
    width: calc(100% - 2px);
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    border: 1px solid #303030;
}
.list-card .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    color: #000;
}
.list-card .flip-card .image {
    height: 100%;
    text-align: center;
}
.list-card .flip-card .image img {
    height: 100%;
    display: inline-block;
    width: 100%;
}
.list-card .flag {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
}
.list-card .flag img {
    display: inline-block;
    padding-top: 40%;
    position: relative;
    z-index: -1;
    width: 200px;
    margin-left: 3px;
}
.list-card .flip-card-back {
    background-color: #fff;
    color: #333;
    transform: rotateY(180deg);
    text-align: left;
    padding: 1px 20px;
    z-index: 10000000;
    position: relative;
}
.flip-inner {
    display: table;
    height: 100%;
    width: 100%;
}
.flip-inner-main {
    display: table-cell;
    vertical-align: middle;
}
.list-card .flip-card-back h3 {
    color: #231f20;
    font-size: 28px;
    font-weight: 700;
    text-transform: inherit;
    font-family: 'Avantt_semibold', sans-serif;
    margin-bottom: 0;
}
.list-card .flip-card-back a {
    color: #f49c00;
    font-size: 18px;
    display: inline-block;
    margin: 20px 0;
}
.card-back p {
    font-size: 16px;
    line-height: 25px;
    color: #333;
}
.list-card .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}
.beers-list .slick-next {
    right: -40px !important;
}
.beers-list .slick-prev:before, .beers-list .slick-next:before {
    color: #fff !important;
}
.beers-list .slick-prev {
    left: -35px !important;
}
.see-all-brands {
    background-image: url(../images/our_brands/brands-callout.png);
    position: relative;
    top: 1px;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    padding: 100px 0;
    width: 100% !important;
    margin: 0 !important;
    margin-bottom: 120px !important;
}
.see-all-brands .details {
    position: relative;
    z-index: 100;
    color: #fff;
}
.see-all-brands .details h2 {
    font-size: 51px;
    line-height: 61px;
    font-family: 'Avantt_semibold', sans-serif;
}
.see-all-brands .details h2 span {
    color: #e6b611 !important;
}
.see-all-brands .details p {
    color: #fff;
}
.see-all-brands .details a {
    display: inline-block;
    border: 1px solid #fff;
    padding: 15px 50px;
    color: #fff;
    border-radius: 3px;
    font-size: 16px;
    letter-spacing: 1px;
    text-decoration: none;
}
.see-all-brands .details a:hover {
    background-image: linear-gradient(to right top, #d3a238, #deb031, #e7c028, #efd01b, #f5e003) !important;
    border: 1px solid #d3a238 !important;
    color: #000 !important;
}
.our-history-banner {
    background: url(../images/our_history/history_banner.png);
}
.leader-banner {
    background: url(../images/our_history/OUR-LEADERS.png);
}
.history-sustainability {
    padding: 90px 0 40px;
    background: #fff;
    position: relative;
}
.history-sustainability h4 {
    color: #e6b611;
    font-family: 'Avantt_semibold', sans-serif;
    font-size: 30px;
    font-weight: bolder;
}
.history-sustainability h2 {
    font-size: 25px;
    letter-spacing: inherit;
    font-family: 'Avantt_semibold', sans-serif;
    text-transform: inherit;
    margin: 39px 0 20px 0;
}
.history-sustainability a {
    border: 1px solid #000;
    color: #000;
    display: inline-block;
    padding: 12px 25px;
    font-size: 17px;
    border-radius: 3px;
    margin-bottom: 50px;
    text-decoration: none;
}
.history-sustainability a:hover {
    background-image: linear-gradient(to right top, #d3a238, #deb031, #e7c028, #efd01b, #f5e003) !important;
    border: 1px solid #d3a238 !important;
    color: #000 !important;
}
.animate-cup {
    min-height: 450px;
    position: relative;
}
.animate-cup .page-not-found {
    font-weight: 700;
    white-space: pre;
    font-family: 'Avantt_semibold', sans-serif;
    font-size: 23px;
}
.not-found-maintainace {
    padding: 0;
    position: relative;
    top: -100px;
}
.svg-404 {
    height: 400px;
    width: 400px;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translatex(-50%) translatey(-50%);
}
.svg-404 svg {
    vertical-align: top;
    width: 100%;
}
.svg-404 svg .beer {
    -webkit-animation-name: empty;
    animation-name: empty;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    fill: #F9CF68;
}

@-webkit-keyframes empty {
    0% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
    30% {
    -webkit-clip-path: polygon(0 16%, 100% 12%, 100% 100%, 0% 100%);
    clip-path: polygon(0 16%, 100% 12%, 100% 100%, 0% 100%);
    }
    50% {
    -webkit-clip-path: polygon(0 40%, 100% 44%, 100% 100%, 0% 100%);
    clip-path: polygon(0 40%, 100% 44%, 100% 100%, 0% 100%);
    }
    70% {
    -webkit-clip-path: polygon(0 69%, 100% 66%, 100% 100%, 0% 100%);
    clip-path: polygon(0 69%, 100% 66%, 100% 100%, 0% 100%);
    }
    90% {
    -webkit-clip-path: polygon(0 90%, 100% 93%, 100% 100%, 0% 100%);
    clip-path: polygon(0 90%, 100% 93%, 100% 100%, 0% 100%);
    }
    100% {
    -webkit-clip-path: polygon(0 90%, 100% 93%, 100% 100%, 0% 100%);
    clip-path: polygon(0 90%, 100% 93%, 100% 100%, 0% 100%);
    }
}    
@keyframes empty {
    0% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
    30% {
    -webkit-clip-path: polygon(0 16%, 100% 12%, 100% 100%, 0% 100%);
    clip-path: polygon(0 16%, 100% 12%, 100% 100%, 0% 100%);
    }
    50% {
    -webkit-clip-path: polygon(0 40%, 100% 44%, 100% 100%, 0% 100%);
    clip-path: polygon(0 40%, 100% 44%, 100% 100%, 0% 100%);
    }
    70% {
    -webkit-clip-path: polygon(0 69%, 100% 66%, 100% 100%, 0% 100%);
    clip-path: polygon(0 69%, 100% 66%, 100% 100%, 0% 100%);
    }
    90% {
    -webkit-clip-path: polygon(0 90%, 100% 93%, 100% 100%, 0% 100%);
    clip-path: polygon(0 90%, 100% 93%, 100% 100%, 0% 100%);
    }
    100% {
    -webkit-clip-path: polygon(0 90%, 100% 93%, 100% 100%, 0% 100%);
    clip-path: polygon(0 90%, 100% 93%, 100% 100%, 0% 100%);
    }
}
.not-found-maintainace .not-found {
    text-align: center;
}
.not-found-maintainace .not-found p {
    margin-bottom: 35px;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -.1px;
    line-height: 30px;
}
.not-found-maintainace .not-found a {
    border: 1px solid #000;
    color: #000;
    display: inline-block;
    padding: 12px 25px;
    border-radius: 3px;
    margin-bottom: 50px;
    font-size: 17px;
    text-decoration: none;
} 
.not-found-maintainace .not-found a:hover {
    background-image: linear-gradient(to right top, #d3a238, #deb031, #e7c028, #efd01b, #f5e003) !important;
    border: 1px solid #d3a238;
    text-decoration: none;
    color: #fff;
}
.history-details {
    float: left;
    width: 50%;
}
.history-images {
    float: left;
    width: 50%;
}
.gcc-benefits {
    background-image: url(../images/GCCBenefits/benefits_banner.png);
}
.rewarding {
    margin: 100px 8% 30px;
    display: table;
    width: 84%;
}
.rewarding .rewards-left {
    font-family: 'Avantt_semibold', sans-serif;
    font-size: 47px;
    display: table-cell;
    width: 50%;
    line-height: 62px;
}
.rewarding .rewards-left span {
    color: #e6b611;
}
.rewarding .rewards-right {
    display: table-cell;
    width: 50%;
    vertical-align: top;
    font-size: 15px;
}
.rewarding .rewards-right p {
    font-size: 16px;
    line-height: 24px;
}
.rewarding .rewards-right p b {
    font-family: 'Avantt_semibold', sans-serif;
}
.tabs-benefits {
    padding: 70px 0;
}
.tabs-benefits .nav-tabs {
    padding: 0 100px;
}
.tabs-benefits .nav-tabs .nav-item, .tabs-benefits .nav-tabs .nav-justified>.nav-link {
    font-size: 18px;
    margin: 0 2px;
    border: 0;
    border-radius: 0;
    letter-spacing: 0.6px;
}
.tabs-benefits .nav-tabs .nav-item button {
    color: #000;
    padding: 10px 0;
    background: #ededed;
    text-transform: uppercase;
    border-radius: 0;
    border: 0;
    font-size: 17px;
    font-family: 'Avantt_semibold', sans-serif;
}
.tabs-benefits .nav-tabs .nav-item button.active {
    background: #e6b611;
    color: #000;
    font-size: 17px;
}
ul.bu-bhq-list-icon {
    list-style: none;
    padding: 100px 0;
    margin: 0;
    text-align: center;
}
ul.bu-bhq-list-icon li {
    width: 23%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 45px;
}
ul.bu-bhq-list-icon li .image img {
    height: 50px;
    margin-bottom: 20px;
}
ul.bu-bhq-list-icon li p {
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
    padding: 0 20px;
}
.gcc-initiatives {
    background-image: url(../images/GCCBetterWorldInitiatives/initiatives_banner_new.png);   
}
.our-entities {
    padding: 60px 0;
    background: #f8f8f8;
}
.our-entities h3 {
    font-family: 'Avantt_semibold', sans-serif;
}
.our-entities h3 {
    font-size: 34px;
    text-transform: uppercase;
    font-weight: 900;
}
.our-entities h3 span {
    color: #e6b611;
}
.program-details .image {
    margin-top: 20px;
}
.program-details .image img {
    height: 180px;
    max-width: 100%;
}
.program-details .details h4 {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 17px;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
    margin: 20px 0;
    font-family: 'Avantt_semibold', sans-serif;
    letter-spacing: 0.6px;
}
.program-details .details p {
    font-size: 14px;
    line-height: 23px;
    color: #666;
    min-height: 170px;
}
.gcc-locations {
    background-image: url(../images/GCC_Locations/location_new.png);
}
.our-locations {
    background: #f8f8f8;
    padding: 90px 0 60px 0;
}
.locations-list-show .locations-details {
    padding: 0;
    display: table;
    width: 100%;
    background: #fff;
    margin-bottom: 30px;
}
.locations-list-show .locations-details .image {
    float: left;
    width: 30%;
}
.locations-list-show .locations-details .address {
    float: left;
    width: 70%;
    vertical-align: top;
    padding: 0 30px;
}
.locations-list-show .locations-details .address h3 {
    font-size: 36px;
    padding-top: 15px;
    margin-bottom: 10px;
    color: #e6b611;
    font-family: 'Avantt_semibold', sans-serif;
}
.our-locations .locations-details p {
    font-size: 17px;
}
.our-locations .locations-details a {
    border: 1px solid #000;
    color: #000;
    display: inline-block;
    padding: 12px 25px;
    font-size: 17px;
    border-radius: 3px;
    margin-bottom: 50px;
    text-decoration: none;
}
.our-locations .locations-details a:hover {
    background-image: linear-gradient(to right top, #d3a238, #deb031, #e7c028, #efd01b, #f5e003) !important;
    border: 1px solid #d3a238 !important;
    color: #000 !important;
}
.bu-benefits {
    background-image: url(../images/BUBenefits/benefits_banner_new.png);
}
ul.bu-bhq-list-icon li h4 {
    font-family: 'Avantt_semibold', sans-serif;
    font-size: 30px;
    margin: 0 0 20px 0;
}
.bu-initiatives {
    background-image: url(../images/BUBetterWorldInitiatives/initiatives_banner_new.png);   
}
.our-ini-bu .program-details .image img {
    height: auto !important;
    width: 100%;
}
.bu-locations {
    background-image: url(../images/BULocations/location_new.png);   
}
.modal {
    z-index: 100000;
}
.sustainability-content img {
    border: 0;
}
@media only screen and (max-width: 767px) {
    .age-gate-content .logo img {
        width: 100px;
    }
    .age-gate-content .logo p {
        font-size: 17px;
        padding: 0 15px;
    }
    .age-gate-content {
        display: block;
    }
    .age-verification-form {
        padding: 0 20px;
    }
    .age-verification-form .form-control {
        margin-bottom: 10px;
        padding: 8px 20px;
    }
    .age-gate-content {
        padding-top: 20px;
    }
    .age-gate-footer {
        position: static;
    }
    .age-verification-form .btn {
        padding: 8px 0;
    }
    /* .navbar-expand-lg .navbar-collapse.menu-links:not(.show) {
        display: block !important;
    }
    .navbar-expand-lg .navbar-collapse.menu-links.show {
        display: none !important;
    } */
    .common-banner {
        margin-top: 50px;
    }
    .fixed-top {
        height: 50px;
    }
    .navbar-toggler {
        background: #fff;
    }
    .navbar-toggler-icon {
        width: 20px;
    }
    .footer-icons ul li img {
        margin: 15px 20px;
    }
    .footer-btm ul.social-icons {
        text-align: center;
    }
    .footer-btm .terms-policy li a {
        float: none;
        margin: 0;
    }
    .footer-btm .terms-policy li {
        display: inline-block;
        margin: 0 15px
    }
    .footer-btm .terms-policy {
        text-align: center;
    }
    .home-banner {
        min-height: 300px;
        max-height: 300px;
    }
    .home-banner .banner-content {
        top: 90px;
    }
    .home-banner .banner-content h1 {
        font-size: 27px;
        line-height: 50px;
    }
    .home-banner .banner-content h1 br {
        display: none;
    }
    .we-are-brewers .we-are-brewers-content p {
        padding-right: 0;
    }
    .history-abinbev p {
        font-size: 25px;
    }
    .common-link {
        padding: 12px 20px;
    }
    .carousel-brands .images {
        width: 100%;
    }
    .carousel-brands .images img {
        height: 300px;
    }
    .carousel-brands .details .carousel-caption {
        padding-top: 0;
    }
    .carousel-brands .details {
        width: 100%;
    }
    .carousel-brands .details .carousel-caption h3 span {
        top: 9px;
        font-size: 25px;
    }
    .what-we-do ul li {
        margin-bottom: 10px;
        width: 100%;
    }
    .what-we-do ul li .portfolioDisc .details p {
        line-height: 22px;
    }
    .what-we-do ul li .portfolioDisc {
        padding: 10px;
    }
    .common-heading {
        font-size: 27px;
    }
    .slick-next {
        right: 0 !important;
    }
    .slick-prev {
        left: 0 !important;
    }
    .menu-links .navbar-nav a {
        width: 100%;
    }
    .menu-links .navbar-nav {
        display: inline-block !important;
        width: calc(100% + 24px) !important;
        margin-left: -12px;
        background: #000;
        padding-bottom: 20px;
    }
    .common-banner .common-banner-inner h1 {
        font-size: 36px;
    }
    .common-banner .common-banner-inner {
        top: 45%;
    }
    .rotate-text {
        top: -15px;
        transform: inherit;
    }
    .common-footer-top {
        height: 400px;
    }
    .common-heading-sub h3 {
        font-size: 31px;
    }
    .navbar-brand {
        height: 50px;
    }
    .common-link {
        padding: 12px 10px;
    }
    .menu-links .navbar-nav .hover-menu {
        width: 100%;
        line-height: 50px;
    }
    .menu-links .navbar-nav a {
        width: 100%;
        line-height: 50px;
    }
    .see-all-brands .details h2 {
        font-size: 31px;
        line-height: 39px;
    }
    .beers-list .slick-next {
        right: 0 !important;
    }
    .beers-list .slick-prev:before, .beers-list .slick-next:before {
        color: #000 !important;
    }
    .beers-list .slick-prev {
        left: 0 !important;
    }
    ul.selection-process-new li .right-content {
        display: block;
        width: 100%;
        text-align: left;
        padding: 0;
        position: relative;
    }
    ul.selection-process-new li .line-left {
        display: none;
    }
    ul.selection-process-new li .right-content h4 {
        font-size: 24px;
    }
    ul.selection-process-new li .right-content span {
        line-height: 45px;
        left: 0;
        font-size: 76px;
        opacity: 1;
        top: 5px;
    }
    ul.selection-process-new li .line-right {
        display: none;
    }
    ul.selection-process-new li .left-content {
        padding: 0;
    }
    ul.selection-process-new li .right-content p {
        padding-left: 50px;
    }
    ul.selection-process-new li .left-content span {
        left: 0;
        float: left;
        opacity: 1;
    }
    ul.selection-process-new li .left-content p {
        padding-left: 50px;
    }
    .selection-process-new:after {
        display: none;
    }
    .kick-start-heading span a {
        padding: 10px 20px;
        font-size: 20px;
    }
    .leaders-list .container {
        width: 100%;
    }
    .leader-new-list .profile-details .image img {
        height: auto;
    }
    h2.head-rotate {
        font-size: 33px;
        transform: inherit;
        left: 0;
        top: 0;
    }
    .carousel-teams {
        padding: 15px 10px;
    }
    .carousel-teams .image {
        width: 100%;
    }
    .carousel-teams .details-main {
        float: left;
        width: 100%;
        padding-left: 0;
    }
    .carousel-teams .details-main p {
        line-height: 29px;
        padding-top: 10px;
    }
    .team-name-main {
        font-size: 30px;
    }
    .analytics-main {
        padding-top: 30px;
    }
    .analytics-main .carousel-teams .details-main {
        padding-right: 0;
    }
    .carousel-banner .common-car-details .common-car-left img {
        height: auto;
        width: 100%;
    }
    .carousel-banner .common-car-details .common-car-right {
        display: block;
        width: 100%;
    }
    .carousel-banner .common-car-details .common-car-left {
        display: block;
        width: 100%;
        padding-right: 0;
    }
    .more-about {
        font-size: 30px !important;
    }
    .our-values-list ul li .list p {
        width: 100%;
    }
    .our-values-list {
        padding: 0px 10px 50px 10px;
    }
    .sustainability-list li {
        width: 100%;
    }
    .sustainability-list .container, .sustainability-content .container {
        width: 100%;
    }
    .di-list-table .di-list-table-left {
        display: block;
        width: 100%;
    }
    .di-list-table .di-list-table-right {
        display: block;
        width: 100% !important;
    }
    .our-initiatives-heading {
        font-size: 30px;
        padding: 0 10px;
    }
    .di-list-table-left {
        padding: 0 10px;
    }
    .di-list-table {
        display: block;
    }
    .di-list-table .di-list-table-right .image video {
        width: 100%;
    }
    .main-img-slider a {
        width: 100%;
    }
    .diversity-awards li {
        width: 100%;
    }
    ul.diversity-awards-top li {
        width: 100%;
    }
    .di-list-table .di-list-table-right {
        padding: 0 10px;
    }
    .scrollmagic-pin-spacer {
        height: auto !important;
        min-height: inherit !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .scrollmagic-pin-spacer > div {
        position: relative !important;
    }
    #pinContainer {
        height: auto !important;
    }
    .panel-main .panel-image, .panel-main .pane-content {
        height: auto !important;
        width: 100% !important;
    }
    #pinContainer .panel {
        transform: inherit !important;
        height: auto !important;
        position: relative !important;
    }
    .panel-main {
        height: auto !important;
        position: relative !important;
    }
    .jXHjba .panel-main .panel-year {
        height: auto;
        width: 100vw;
        position: static;
        line-height: inherit;
        float: left;
    }
    .jXHjba .panel-main .pane-content {
        padding: 20px;
    }
    .jXHjba .panel-main .panel-year p {
        color: rgb(230, 182, 17);
        letter-spacing: -0.1px;
        font-size: 56px;
        font-family: Avantt_semibold, sans-serif;
        margin-bottom: 0;
        text-align: left;
        padding: 25px 0 0 20px;
    } 
}
hr{
    color: #edcc1c;
     border-width: 3px; 
     opacity: 1;
     margin: 0rem 0;
}
.footer-btm ul.social-icons img {
    object-fit: contain;
}
/* html .dOEAVb .panel-main .panel-image{
    height: 80vh;
    padding: 5% 10%;
}
html .dOEAVb .panel-main .pane-content{
    height: 80vh;
    padding: 5% 10%;
} */
html .dOEAVb .panel-main .pane-content h3{font-weight: 600;}
html .dOEAVb .panel-main .pane-content{padding: 7%;}
.anat_eiar img {
    max-width: 100%;
    box-shadow: 0 0 2px 5px #333;
}
.diversity-inclusion-equal.stack_cls {
    background-color: #f8f8f8;
    padding: 75px 0;
    margin-top: 35px;
}
.diversity-inclusion-equal.stack_cls h2{text-transform: uppercase;text-align: left;}
.diversity-inclusion-equal.stack_cls h2 span{color: #d3a238;}
.parental-policy-box{margin-top: 30px;margin-bottom: 110px;}
.parental-policy-box h3{margin-bottom: 25px;}
p.font_style_cls{
    font-size: 24px;
    text-align: center;
    line-height: 34px;
    margin-top: 10px;
}
.key_takeaways {
    margin-bottom: 90px;
}
.common-programme-content ul li {
    flex-basis: 24%;
    padding: 25px;
    margin-right: 1%;
    list-style-type: none;
    font-size: 16px;
    line-height: inherit;
    border-radius: 4.373px;
    border: 1.749px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
}
.common-programme-content ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}

.common-programme-content ul li b {
    display: block;
    width: 100%;
    font-size: 19px;
    position: relative;
    padding-top: 20px;
    padding-bottom: 7px;
}
.common-programme-content ul li b::before {
    content: "";
    width: 100px;
    height: 3px;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(271deg, #E4B131 -1.06%, #F5E003 339.22%);
}
.common-programme-content ul li img {
    max-width: 40px;
    margin: 0 auto 20px;
    display: flex;
    margin-left: 0;
}
.bottom_top {
    margin-bottom: 110px;
    text-align: left;
    font-size: 23px;
}
.mar_b{margin-bottom: 0;}
.what-we-do.home_who ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}
.what-we-do.home_who ul li{width: 32%;
    margin-right: 1%;}
.what-we-do.home_who ul li .portfolioDisc .details p{line-height: 21px;font-size: 16px;}
.what-we-do.home_who ul li .portfolioDisc{top: 0;bottom: unset; height: 100%;line-height: 20px;}
.carousel-brands .carousel-indicators {
    left: 0;
    right: 0;
    width: 100%;
    margin: 0;
}
.describe_cls {
    opacity: 0;
    transition: all 300ms;
    bottom: 100%;
    position: absolute;
    width: 100%;
}

.portfolioDisc:hover .describe_cls {
    opacity: 1;
    height: auto;
    bottom: -25%;
    position: relative;
    width: 100%;
}

.cyber{
    display: flex;
    align-items: center;
    justify-content: center;
}
.cyber_team{align-items: center;}



.card {
    min-height: 350px;
    width: 100%;
    margin-bottom: 20px;
    background-color: black;
    color: white;
    padding: 20px;
    box-sizing: border-box;
    border: 1px rgba(255, 255, 255, 0.60) dashed;
}

img.card-icon {
    width: 36px !important;
    height: 36px !important;
    object-fit: contain !important;
}
.cyber_sequ .card{min-height: unset; height: 100%;margin-bottom: 0;}
.cyber_sequ .card .row1 {
    margin-bottom: 20px;
}
.cyber_sequ .card h5{margin-top: 15px;font-size: 18px;}
.cyber_sequ .row2 {
    margin-top: 25px;
}
.cyber_sequ .card p{    margin-bottom: 0;
    font-size: 14px !important;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.80);}
.cyber_sequ h2{margin-bottom: 25px;}
.cyber_sequ{padding: 20px 0 60px;}
.sub_do{padding-bottom: 0;}
.image img {
    max-width: 100%;
    width: 100%;
}
.campus-prog .image {
    margin-bottom: 25px;
}
.campus-prog a {
    border: 0.689px solid #000;
    background: rgba(255, 255, 255, 0.10);
    -webkit-backdrop-filter: blur(1.7229729890823364px);
    backdrop-filter: blur(1.7229729890823364px);
    text-decoration: none;
    padding: 10px;
    color: #000;
    font-size: 14px;
}
.campus-prog p{
    font-size: 14px;
    margin-bottom: 25px;
}
.campus-prog h4{
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}
.we-are-brewers {
    margin-top: 60px;
}
.we-are-brewers .row{align-items: center;}
.we-are-brewers .strip{margin-top: 0;}
.we-are-brewers .image{box-shadow: 0px 7px 19px 0px rgba(0, 0, 0, 0.10);}
.bottom_text_cls {width: 100%;margin-top: 20px;float: left;font-size: 16px;font-weight: 400;}
.sub_do{display: none;}
.cyber_team .details-main h3 {
    position: relative;
    padding-top: 10px;
}
.cyber_team .details-main h3::before {
    content: "";
    position: absolute;
    top: 0;
    width: 70px;
    height: 4px;
    background: #e6b611;
    left: 0;
}
.text_upper{text-transform: uppercase;}
.what-we-do-heading h3.common-heading {
    margin-bottom: 20px;
}
/* .details {
    display: flex;
    align-items: center;
} */
.key_takeaways h3 {
    margin-bottom: 20px;
}
.apply_now_cls{text-align: center;margin-bottom: 90px;}
.apply_now_cls p {
    font-size: 29px;
    line-height: 43px;
}
.bottom_tow {
    margin-bottom: 0;
}
.common-banner.main-brands-banner {
    background-color: rgba(0,0,0,0.9);
    background-blend-mode: overlay;
}
.fixed_contact {
    position: fixed;
    bottom: 55%;
    left: calc(100% - 85px);
    min-width: 150px;
    text-align: center;
    transform: rotate(90deg);
    background-color: #000;
    border-radius: 40px;
    padding: 15px;
    box-shadow: 0 0 3px #ccc;
}
.fixed_contact:hover{box-shadow: 0 0 6px #ccc;}
.fixed_contact a {
    text-decoration: none;
    color: #e6b611;
    font-weight: bold;
}

/* ************** cookie **************** */
.cookie-popup {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px;
    border-radius: 5px;
    max-width: 1200px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    background: #FFF;
    stroke-width: 1px;
    border: 1px solid #D9DBE9;
    box-shadow: 0px 14px 42px rgba(8, 15, 52, 0.06);
}
.cookie-popup h4 {
    font-weight: bold;
    font-size: 20px;
}
  .cookie-popup p {
    margin-bottom: 10px;
    flex-basis: 95%;
    font-size: 18px;
}
.cookie-popup p span {
    text-align: left;
    display: inline-block;
    width: 90%;
    vertical-align: bottom;
    padding-left: 17px;
}
  .button-container {
    display: flex;
    justify-content: space-around;
    flex-basis: 5%;
    align-items: baseline;
  }
  
  .button-container button {
    padding: 8px 16px;
    cursor: pointer;
    background: #FFF;
    border: 1px solid #D9DBE9;
    box-shadow: 0px 14px 42px rgba(8, 15, 52, 0.06);
    font-size: 16px;
    font-weight: 700;
    line-height: 18px; /* 112.5% */
  }
  
  .button-container button:first-child {
    background: #000;
    box-shadow: 0px 3px 12px 0px rgba(74, 58, 255, 0.18);
    color:  #FFF;
  }
  .button-container button:first-child span {
    display: inline-block;
    padding-left: 10px;
    vertical-align: baseline;
}
.cookie_wrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.60);
    backdrop-filter: blur(7.5px);
}

span.close-button {
    display: flex;
    align-items: center;
    cursor: pointer;
}

/* *************** privacy police ******************** */
.top_pera {
    padding: 150px 0 39px;
}
.fullSection table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}
.fullSection th, .fullSection td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}
.fullSection th {
    background-color: #f2f2f2;
}
.fullSection ul {
    margin: 0;
}
.fullSection ul li {
    margin-bottom: 10px;
    font-size: 18px;
}
.fullSection h3{font-weight: 700;font-size: 18px;}
.fullSection p{font-size: 18px;}
ul.QTag a {
    text-decoration: none;
    font-size: 18px;
    line-height: 2;
}
.top_pera h2{
    font-size: 22px;
    font-weight: 700;
}
.fullSection {
    padding-bottom: 20px;
    padding-top: 20px;
}
/* *************** close ******************** */

@media(min-width:1024px) {
    .common-banner{height: 450px;}
}
@media(min-width:1360px) {
    .common-banner{height: 400px;}
}
@media(min-width:1600px) {
    .common-banner{height: 540px;}
}
@media(max-width:1360px) {
    .fixed_contact{bottom: 45%;}
}